@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.row {
  position: relative;
  padding: 3rem 0 1.5rem;
  border-top: 1px solid $grey-lighter;

  .loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .removeButton {
    position: absolute;
    right: 0;
    top: .5rem;
  }
}
