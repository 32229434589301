@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.station {
  background: #f4f7f8;

  // Sticky footer
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
  }
}

.header {
  padding: 1rem;
  background: #ffffff;
}

.headerInner {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    width: 33.333%;
  }

  .homeButtonContainer {
    position: relative;

    .hotkeyHint {
      position: absolute;
      left: 0;
      bottom: -1.8em;
      font-size: $size-7;
      color: $grey-light;
    }
  }

  .logoContainer {
    display: flex;
    justify-content: center;
  }
}

.footer {
  position: relative;
  padding: 2rem;
  text-align: center;
  color: $grey;
  font-size: $size-7;

  .secret {
    position: absolute;
    bottom: 2px;
    right: 4px;

    a {
      font-size: $size-7;
      color: $grey-light;
    }
  }
}
