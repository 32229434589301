@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.badge {
  display: inline-block;
  padding: 3px 7px;
  margin-right: 2px;
  margin-top: 4px;
  background: $white-ter;
  font-size: $size-7;
  border-radius: $radius-small;

  &.level-basic {
    background: beige;
  }

  &.level-super {
    background: #13c1ce;
    color: $white;
  }

  &.level-coordinator {
    background: rgb(238, 174, 202);
    background: linear-gradient(
      rgba(238, 174, 202, 1) 0%,
      rgba(148, 187, 233, 1) 100%
    );
    color: $white;
    font-weight: bold;
  }
}
