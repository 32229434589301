@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";
@import "~bulma/sass/utilities/mixins.sass";

.wrapper {
  @include desktop {
    display: none;
  }
}

.hamburger {
  position: absolute;
  z-index: 4;
  top: 1.3rem;
  right: 1.5rem;
  width: 36px;
  height: 30px;

  > span {
    > span {
      position: absolute;
      height: 16%;
      left: 0px;
      right: 0px;
      opacity: 1;
      border-radius: 1px;

      top: 0;

      background: $black;
    }
  }
}

.cross {
  position: absolute;
  z-index: 4;
  width: 30px;
  height: 30px;
  top: 1.1rem;
  right: 1.2rem;

  > span {
    position: absolute;
    top: 0;
    right: 16px;

    > span {
      position: absolute;
      width: 3px;
      height: 30px;
      background: $black;

      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }
}

.hamburger button, .cross button {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    border: none;
    font-size: 0px;
    background: transparent;
    cursor: pointer;
}

.overlay {
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0,0,0,0.4);
  transition: opacity 0.5s ease 0s;

  transform: translate3d(100%, 0px, 0px);
  opacity: 0;

  &.overlayIsOpen {
    transform: none;
    opacity: 1;
  }
}

.menu {
  position: fixed;
  z-index: 5;
  width: 250px;
  max-width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: $white;
  overflow-y: auto;
  transition: all 0.5s ease 0s;

  transform: translate3d(100%, 0px, 0px);

  padding: 4rem 1.5rem 1.2rem;

  &.isOpen {
    transform: none;
  }

  .nav {
    ul {
      list-style: none;

      li {
        a.navLink {
          display: block;
          padding: 0.5rem 0;
          color: $grey-dark;
          font-weight: $weight-medium;

          &.navLinkActive,
          &:hover {
            color: $black;
          }

          &.navLinkActive {
            border-radius: 4px;
            background-color: $light;

            margin: 0 -1.5rem;
            padding: 0.5rem 1.5rem;
          }
        }

        > ul {
          > li {
            > a.navLink {
              padding-left: 1rem;

              &.navLinkActive {
                margin-left: -1.5rem;
                margin-right: -1.5rem;
                padding-left: 2.5rem;
                padding-right: 1.5rem;
              }
            }
          }
        }
      }
    }
  }

  hr {
    margin: 1rem 0 1.5rem;
  }

  .authWrapper {
    font-size: $size-7;
    div {
      margin-top: 5px;
    }
  }
}
