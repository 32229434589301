@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.item {
  padding: 1.5rem;
  border-bottom: 1px solid $grey-lighter;

  // &:last-of-type {
  //   border-bottom: none;
  // }
}
