@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.dropzone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 20rem;
  max-width: 100%;
  margin-bottom: 1.5rem;
  border: 3px dashed $grey-lighter;
  border-radius: $radius-large;
  cursor: pointer;

  &.isFullWidth {
    height: 7.5rem;
    width: 100%;
  }

  &.disabled {
    background-image: linear-gradient(
      45deg,
      $white-ter 25%,
      $white 25%,
      $white 50%,
      $white-ter 50%,
      $white-ter 75%,
      $white 75%,
      $white 100%
    );
    background-size: 28.28px 28.28px;
    border-style: solid;
  }

  &.accept {
    border-color: $green;
  }

  &.reject {
    border-color: $red;
  }

  // drag/click info
  > p {
    margin-bottom: 0 !important;
    font-size: $size-7;
  }

  // preview
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transform: translate(-50%, -50%);
  }

  .delete {
    // extends bulma delete button (x)
    position: absolute;
    right: -10px;
    top: -10px;
    background: $grey-lighter;
    &:hover {
      background: $grey-light;
    }
  }
}
