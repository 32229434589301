@import "~bulma/sass/utilities/initial-variables.sass";

// Customization
// $link: $purple;
// $family-sans-serif: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-sans-serif: Roboto, -apple-system, BlinkMacSystemFont, "Helvetica Neue",
  Helvetica, sans-serif;

@import "~bulma/bulma.sass";

hr {
  margin: 2rem 0;
}

.has-standard-mb {
  margin-bottom: 1.5rem !important;
}

.has-tiny-mb {
  margin-bottom: 0.5em !important;
}

.is-marginless-bottom {
  margin-bottom: 0 !important;
}

// adjust shadow on box
.box {
  box-shadow: 0 1px 3px rgba(10, 10, 10, 0.1);
}

// bulma select caret
.select:not(.is-multiple):not(.is-loading)::after {
  // default is 4; but it was appearing above react-select dropdowns
  z-index: 0;
}

.sq-input {
  @include input;
  padding: 0;

  &.sq-input--error {
    border-color: $danger;

    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
      box-shadow: $input-focus-box-shadow-size rgba($danger, 0.25);
    }
  }
}

.simple-spinner {
  @include loader;
  display: inline-block;
  font-size: 0.75em;
  margin-right: 0.5em;
}

.has-soft-yellow-bg {
  background: rgba($yellow, 0.15);
  border-radius: $radius-small;
  padding: 1px 5px 2px;
}
