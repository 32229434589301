:global {
  body {
    overflow-x: hidden;
    min-height: 100vh;
  }

  // Not the best practice, but target <p> without a specified
  // class and, assuming it is a textual body content paragraph,
  // add some margin to the bottom of it.
  // Unless it's the last child.
  // Or force it (either for last child or paragraphs with a class)
  // using the class .has-margin-bottom
  p:not([class]):not(:last-child),
  p.has-margin-bottom {
    margin-bottom: 1em;
  }

  @import "./nprogress.scss";

  // hide google recaptcha badge
  .grecaptcha-badge {
    	visibility: collapse !important;
  }

  ul.bulleted {
    list-style: disc inside;
  }
}
