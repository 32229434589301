@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";
@import "~bulma/sass/utilities/functions.sass";

// sticky footer with flexbox
// from https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.main {
  flex: 1;

  &.noPaddingTop {
    padding-top: 0;
  }
}
.siteAlert {
  width: 100%;
  font-size: $size-small;
  $color: $primary;
  $color-invert: $primary-invert;
  // $color: $danger;
  // $color-invert: $danger-invert;
  $color-lightning: max((100% - lightness($color)) - 2%, 0%);
  $color-luminance: colorLuminance($color);
  $darken-percentage: $color-luminance * 70%;
  $desaturate-percentage: $color-luminance * 30%;
  background-color: lighten($color, $color-lightning);
  color: desaturate(darken($color, $darken-percentage), $desaturate-percentage);
  padding: 0.8em 1em;
  text-align: center;

  a,
  a:hover {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }

  strong {
    color: inherit;
  }
}
.fundraisingMessageSection {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.fundraisingMessage {
  margin-top: 1.5em !important;
}
