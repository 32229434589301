@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";
@import "~bulma/sass/utilities/mixins.sass";

$header-height: 10rem;

.header {
  position: relative;
}

.inner {
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include touch {
    justify-content: center;
  }

  @include mobile {
    // more whitespace for "donate to bsbc" button
    margin-top: 2rem;
  }

  .left,
  .right {
    flex: 2;
    display: flex;

    @include touch {
      display: none;
    }
  }

  .left {
    justify-content: flex-end;
    padding-right: 1rem;
  }

  .right {
    justify-content: flex-start;
    padding-left: 1rem;
  }
}

// Nav

.nav {
  > ul {
    list-style: none;
    margin: 0;
    padding-left: 0;

    > li {
      display: block;
      float: left;
      position: relative;
      text-decoration: none;
      transition-duration: 0.5s;
      padding: 0 1em;

      &:hover,
      &:focus-within {
        cursor: pointer;
      }

      > a {
        color: $grey-dark;
        font-weight: $weight-medium;
        padding: 0.5em 0.75em;

        &.activeItem,
        &:hover {
          color: $black-ter;
        }

        &.activeItem {
          border-radius: 4px;
          background-color: $light;
        }
      }

      &:focus-within a {
        outline: none;
      }

      // Sub-menu

      > ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
        visibility: hidden;
        opacity: 0;
        width: 9rem;
        position: absolute;
        margin-top: 0.3em;
        padding-top: 0.6em;
        left: 1em;
        display: none;
        z-index: 3;

        transition: all 0.5s ease;

        > li {
          float: left;
          position: relative;

          background: $light;
          display: block;
          clear: both;
          width: 100%;

          &:first-of-type {
            padding-top: 0.3em;
          }
          &:last-of-type {
            padding-bottom: 0.3em;
          }

          > a {
            display: block;
            color: $black-ter;
            padding: 0.3em 0.75em;
            font-size: 0.9em;

            &.activeItem,
            &:hover {
              color: $black;
              background-color: darken($light, 2%);
            }

            &.activeItem {
              background-color: $grey-lighter;
            }
          }
        }
      }

      &:hover > ul,
      &:focus-within > ul,
      & > ul:hover,
      & > ul:focus {
        visibility: visible;
        opacity: 1;
        display: block;
      }
    }
  }
}

.item {
  display: block;
  padding: 0 1em;

  a {
    color: $grey-dark;
    font-weight: $weight-medium;
    padding: 0.5em 0.75em;

    &.activeItem,
    &:hover {
      color: $black-ter;
    }

    &.activeItem {
      border-radius: 4px;
      background-color: $light;
    }
  }

  .subItemMenu {
    display: none;
  }
}

// Remote

$remote-margin-top: 2rem;
$remote-margin-side: 2rem;

.remote {
  @include touch {
    display: none;
  }

  position: absolute;
  top: $remote-margin-top;
  z-index: 1; // position remote above the static full-width header

  &.remoteLeft {
    left: $remote-margin-side;

    span[aria-label="bicycle"] {
      // bicycle emoji is a bit low
      padding-bottom: 0.25em;
    }
  }

  &.remoteRight {
    right: $remote-margin-side;
    text-align: right;

    > div {
      margin-top: 0.75em;
      font-size: $size-7;
    }
  }
}

// display mini banner only on touch (non-desktop)
.touchBanner {
  @include desktop {
    display: none;
  }

  // text-align: center;
  position: absolute;
  z-index: 4;
  top: 1.1rem;
  left: 1.5rem;
}
