@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.list {
  list-style: disc inside;
  font-size: $size-4;

  li {
    margin-bottom: 0.5rem;
  }
}

.editorWrapper {
  display: inline-block;
  vertical-align: middle;
}
