.frozen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  &.fading {
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
  }
}

.written {
  font-size: 2rem;
  font-style: italic;
  text-align: center;
  margin-left: 2rem;
}

$wheelSize: 100px;
$rimWidth: 5px;
$tireWidth: 5px;
$spokeRimColor: #dadada;
$tireColor: #333;

.wheel {
  position: relative;
  width: $wheelSize;
  height: $wheelSize;
  border-radius: 100%;
  border: $rimWidth solid $spokeRimColor;
  box-shadow: 0 0 0 $tireWidth $tireColor;

  animation-name: wheelspin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  .spoke {
    margin-left: ($wheelSize - ($rimWidth * 2)) / 2;
    height: $wheelSize - ($rimWidth * 2);
    width: 2px;
    position: absolute;
    background: $spokeRimColor;

    &:nth-child(2) {
      transform: rotate(30deg);
    }

    &:nth-child(3) {
      transform: rotate(60deg);
    }

    &:nth-child(4) {
      transform: rotate(90deg);
    }

    &:nth-child(5) {
      transform: rotate(120deg);
    }

    &:nth-child(6) {
      transform: rotate(150deg);
    }

    &:nth-child(7) {
      transform: rotate(180deg);
    }

    &:nth-child(8) {
      transform: rotate(210deg);
    }
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes wheelspin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
