@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.quickSelectUserButtons {
  white-space: normal;
  line-height: 3em;

  span {
    display: inline-block;
  }
}
