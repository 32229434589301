@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.footer {
  padding: 7rem 1rem 6rem;
}

.social {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;

  > button {
    margin-right: 1em;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.smallGrayLinks {
  display: flex;
  justify-content: center;
  margin: 0 auto 2.5em;
}

.smallGrayLink {
  display: block;
  margin-right: 2em;
  color: $grey-light;
  font-size: $size-7;
  font-weight: $weight-medium;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    color: $grey;
  }
}

.copy {
  text-align: center;
  color: $grey-light;
  font-size: $size-7;

  a {
    color: inherit;
    text-decoration: underline;
  }
}
