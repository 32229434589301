@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.leaderboard {
  margin-bottom: 2rem;
}

.users {
}

.user {
  background: $white-ter;
  border-radius: $radius-large;
  padding: 1em;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

// .squares {
//   display: flex;
// }

// $square-size: 10px;
// .square {
//   display: block;
//   height: $square-size;
//   width: $square-size;
//   background-color: $grey-lighter;
//   margin-right: 5px;

//   &:last-child {
//     margin-right: 0;
//   }

//   &.green {
//     background-color: $green;
//   }
// }
