@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.byType {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}
