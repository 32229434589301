@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";
@import "~bulma/sass/utilities/mixins.sass";

.wrapper {
  margin: 5em 0;
}

.formContainer {
  display: flex;
  justify-content: center;

  &.big {
    @include mobile {
      display: none;
    }
  }
  &.small {
    @include tablet {
      display: none;
    }
  }
}

.email {
  width: 50%;
}
