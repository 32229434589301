@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.existing {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;

  .name {
    margin-right: .75rem;
  }

  .mrTag {
    margin-right: .5rem;
    text-transform: capitalize;
  }

  .quantity {
    width: 60px;
  }
}
