@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/derived-variables.sass";

.box {
  &:hover {
    box-shadow: 0 1px 3px rgba(10, 10, 10, 0.15);
  }
}

.biglink {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: inherit;
  padding: 1.25rem; // since parent .box had padding removed

  .hotkeyHint {
    position: absolute;
    bottom: 1rem;
    right: 1.25rem;
    text-align: center;
    color: $grey;
    font-size: $size-7;
  }
}
